






















































import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class CapitalLogs extends Vue { 
  pagerHeader: any = {
    title: "平台收益列表",
    desc: "平台收益列表",
    breadcrumb: ["会员管理", "平台收益列表"]
  };
  loading: boolean = false;
  filter: any = {
    orderId: null,  //订单ID
    beginTime: null,
    endTime: null,
    page: 1,
    size: 10,
    sortField: null,
    sortDirection: null
  };
//   reasonValues: any = [];
  dateRange: any = [];
  tableData: any = {
    items: [],
    totalCount: 0
  };
    //日期改变   
  timeVoi(value:Date[]){
    if (value != null && value.length == 2) {
        this.filter.startDate=value[0].Format("yyyy-MM-dd hh:mm:ss")
        if(value[1].Format('hh')=="00"&&value[1].Format('mm')=="00"&&value[1].Format('ss')=="00"){
            this.filter.endDate = value[1].Format("yyyy-MM-dd 23:59:59");
            this.dateRange=[this.filter.startDate=value[0].Format("yyyy-MM-dd hh:mm:ss"),this.filter.endDate = value[1].Format("yyyy-MM-dd 23:59:59")]
        }else{
            this.filter.endDate = value[1].Format("yyyy-MM-dd hh:mm:ss")
            this.dateRange=[this.filter.startDate=value[0].Format("yyyy-MM-dd hh:mm:ss"),this.filter.endDate = value[1].Format("yyyy-MM-dd hh:mm:ss")]
        }
        this.filter.page=1
      this.readerPage()
    } else {
      this.filter.startDate = "";
      this.filter.endDate = "";
      this.readerPage()
    } 
  }
  // 分页开始
  handleSizeChange(val: number) {
    this.filter.size = val;
    this.readerPage();
  }
  handleCurrentChange(val: number) {
    this.filter.page = val;
    this.readerPage();
  }
//   请求数据
  async readerPage() {
    this.loading = true;
    try {
      let res = await this.$ajax.post(
        "/api/services/app/Platform/QueryPagedDeivideLogs",
        {
          ...this.filter
        }
      );

      if (res.data.success) {
          console.log(res);
        this.tableData = res.data.result;
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }
//   自动搜索会员
    async readerMemberOptions(query: string) {
    this.memberSearchLoading = true;
    try {
      var res = await this.$ajax.post(
        "/api/services/app/Member/QueryPagedList",
        {
          page: 1,
          size: 10,
          keywords: query
        }
      );
      this.memberItems = res.data.result.items;
    } catch (error) {}
    this.memberSearchLoading = false;
  }
//点击搜索
  search() {
    this.filter.page = 1;
    // this.filter.keywords = this.keywords;
    this.readerPage();
  }
  dateVo(e:any){
      console.log(e);
      
  }

  created() {
          // 根据参数知道是否需要根据时间搜索 从统计点击过来
    if(this.$route.query.time==="0"){
        this.getTime(0)
    }else if(this.$route.query.time==="1"){
        this.getTime(1)
    }else if(this.$route.query.time==="2"){
        this.getTime(7)
    }else if(this.$route.query.time==="3"){
        this.getTime(30)
    }
    this.filter.size = this.systemSettings.pageSize;
    if (this.$route.query.id) {
      this.filter.orderId = this.$route.query.id;
    }
    this.readerPage();
  }
// 处理传过来的时间数字
    getTime(days:any){
        if(days==1){
           // @ts-ignore
            this.filter.startDate =new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 00:00:00")
            // @ts-ignore
            this.filter.endDate =new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 23:59:59")
           // @ts-ignore
            this.dateRange=[new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 00:00:00"),new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 23:59:59")]
        }else{
            // @ts-ignore
            this.filter.startDate =new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 00:00:00")
            this.filter.endDate =new Date().Format("yyyy-MM-dd 23:59:59")
            // @ts-ignore
            this.dateRange=[new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 00:00:00"),new Date().Format("yyyy-MM-dd 23:59:59")]
        }
            
    }

  get reasonOptions() {
    return this.getEnum("MemberBalanceFrozenReason");
  }

  memberSearchLoading: boolean = false;
  memberItems: any[] = [];


  async sortChange(e: any) {
    console.log(e);
    if (e.order) {
      this.filter.sortField = e.prop;
      if (e.order == "ascending") {
        this.filter.sortDirection = 0;
      } else {
        this.filter.sortDirection = 1;
      }
    } else {
      this.filter.sortDirection = null;
      this.filter.sortField = null;
    }
    this.readerPage();
  }
}
